<template>
  <div class="ud-body ud-body-card">
    <!-- 顶部卡片 -->
    <a-card :bordered="false" :body-style="{ padding: '20px' }">
      <div class="ud-cell workplace-user-card">
        <div class="ud-cell-content ud-cell">
          <a-avatar :size="68" :src="loginUser.avatar" />
          <div class="ud-cell-content">
            <h4 class="ud-elip">
              早安，{{ loginUser.nickname }}，开始您一天的工作吧！
            </h4>
            <div class="ud-elip ud-text-secondary">
              <cloud-outlined />
              <em>今日多云转阴，18℃ - 22℃，出门记得穿外套哦~</em>
            </div>
          </div>
        </div>
        <div class="workplace-count-group">
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <ud-tag color="blue" shape="circle" size="small">
                <appstore-filled />
              </ud-tag>
              <span class="workplace-count-name">项目数</span>
            </div>
            <h2>3</h2>
          </div>
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <ud-tag color="orange" shape="circle" size="small">
                <check-square-outlined />
              </ud-tag>
              <span class="workplace-count-name">待办项</span>
            </div>
            <h2>6 / 24</h2>
          </div>
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <ud-tag color="green" shape="circle" size="small">
                <bell-filled />
              </ud-tag>
              <span class="workplace-count-name">消息</span>
            </div>
            <h2>1,689</h2>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 快捷方式块 -->
    <a-row :gutter="16">
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/system/user" class="app-link-block">
            <user-outlined class="app-link-icon" />
            <div class="app-link-title">用户</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/dashboard/analysis" class="app-link-block">
            <shopping-cart-outlined
              class="app-link-icon"
              style="color: #95de64"
            />
            <div class="app-link-title">分析</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/list/card/project" class="app-link-block">
            <fund-projection-screen-outlined
              class="app-link-icon"
              style="color: #ff9c6e"
            />
            <div class="app-link-title">商品</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/list/basic" class="app-link-block">
            <file-search-outlined
              class="app-link-icon"
              style="color: #b37feb"
            />
            <div class="app-link-title">订单</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/list/advanced" class="app-link-block">
            <credit-card-outlined
              class="app-link-icon"
              style="color: #ffd666"
            />
            <div class="app-link-title">票据</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/user/message" class="app-link-block">
            <mail-outlined class="app-link-icon" style="color: #5cdbd3" />
            <div class="app-link-title">消息</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/extension/more" class="app-link-block">
            <tags-outlined class="app-link-icon" style="color: #ff85c0" />
            <div class="app-link-title">标签</div>
          </router-link>
        </a-card>
      </a-col>
      <a-col :lg="3" :md="6" :sm="12" :xs="12">
        <a-card :bordered="false" hoverable :body-style="{ padding: 0 }">
          <router-link to="/user/profile" class="app-link-block">
            <control-outlined class="app-link-icon" style="color: #ffc069" />
            <div class="app-link-title">配置</div>
          </router-link>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <!-- 最新动态 -->
      <a-col :lg="8" :md="24" :sm="24" :xs="24">
        <a-card title="最新动态" :bordered="false" :body-style="{ padding: 0 }">
          <div
            style="height: 347px; padding: 28px 20px 0 20px"
            class="ud-scrollbar-hover"
          >
            <a-timeline>
              <a-timeline-item
                v-for="(item, index) in activities"
                :key="index"
                :color="item.color"
              >
                <em>{{ item.time }}</em>
                <em>{{ item.title }}</em>
              </a-timeline-item>
            </a-timeline>
          </div>
        </a-card>
      </a-col>
      <!-- 我的任务 -->
      <a-col :lg="8" :md="24" :sm="24" :xs="24">
        <a-card
          title="我的任务"
          :bordered="false"
          :body-style="{ padding: '10px 8px 10px 8px;' }"
        >
          <div class="ant-table ant-table-middle">
            <div class="ant-table-content">
              <table style="min-width: max-content">
                <thead class="ant-table-thead">
                  <tr>
                    <th></th>
                    <th style="text-align: center">优先级</th>
                    <th>任务名称</th>
                    <th style="text-align: center">状态</th>
                  </tr>
                </thead>
              </table>
              <div
                class="ant-table-body ud-scrollbar-hover"
                style="overflow-x: hidden; height: 244px"
              >
                <table style="min-width: max-content">
                  <colgroup>
                    <col width="48" />
                    <col width="60" />
                    <col />
                    <col width="80" />
                  </colgroup>
                  <draggable
                    tag="tbody"
                    item-key="id"
                    v-model="taskList"
                    :component-data="{ class: 'ant-table-tbody' }"
                    handle=".anticon-menu"
                    :animation="300"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td style="text-align: center">
                          <menu-outlined style="cursor: move" />
                        </td>
                        <td style="text-align: center; padding: 8px">
                          <ud-tag
                            :color="
                              ['red', 'orange', 'blue'][element.priority - 1]
                            "
                            shape="circle"
                          >
                            {{ element.priority }}
                          </ud-tag>
                        </td>
                        <td>
                          <a>{{ element.taskName }}</a>
                        </td>
                        <td style="text-align: center">
                          <span
                            :class="
                              [
                                'ud-text-warning',
                                'ud-text-success',
                                'ud-text-info'
                              ][element.state]
                            "
                          >
                            {{ ['未开始', '进行中', '已完成'][element.state] }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </draggable>
                </table>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
      <!-- 本月目标 -->
      <a-col :lg="8" :md="24" :sm="24" :xs="24">
        <a-card title="本月目标" :bordered="false">
          <div class="workplace-goal-group">
            <a-progress
              type="dashboard"
              :percent="80"
              :show-info="false"
              :width="181"
              :stroke-width="4"
            />
            <div class="workplace-goal-content">
              <ud-tag color="blue" size="large" shape="circle">
                <trophy-outlined />
              </ud-tag>
              <div class="workplace-goal-num">285</div>
            </div>
            <div class="workplace-goal-text">恭喜，本月目标已达标！</div>
          </div>
        </a-card>
      </a-col>
      <!-- 项目进度 -->
      <a-col :lg="16" :md="24" :sm="24" :xs="24">
        <a-card
          title="项目进度"
          :bordered="false"
          :body-style="{ padding: '12px 12px 1px 12px' }"
        >
          <template #extra>
            <a>查看更多<RightOutlined /></a>
          </template>
          <a-table
            :data-source="projectList"
            row-key="id"
            :pagination="pagination"
            size="middle"
            :scroll="{ x: 'max-content' }"
          >
            <a-table-column key="index" align="center" :width="48">
              <template #default="{ index }">{{ index + 1 }}</template>
            </a-table-column>
            <a-table-column title="项目名称" data-index="projectName">
              <template #default="{ text }">
                <a>{{ text }}</a>
              </template>
            </a-table-column>
            <a-table-column title="开始时间" data-index="startDate" />
            <a-table-column title="结束时间" data-index="endDate" />
            <a-table-column
              title="状态"
              data-index="state"
              align="center"
              :width="90"
            >
              <template #default="{ text }">
                <span
                  :class="
                    [
                      'ud-text-success',
                      'ud-text-danger',
                      'ud-text-warning',
                      'ud-text-info ud-text-delete'
                    ][text]
                  "
                >
                  {{ ['进行中', '已延期', '未开始', '已结束'][text] }}
                </span>
              </template>
            </a-table-column>
            <a-table-column
              title="进度"
              data-index="progress"
              align="center"
              :width="180"
            >
              <template #default="{ text }">
                <a-progress :percent="text" size="small" />
              </template>
            </a-table-column>
          </a-table>
          <!-- 分页 -->
        </a-card>
      </a-col>
      <!-- 小组成员 -->
      <a-col :lg="8" :md="24" :sm="24" :xs="24">
        <a-card title="小组成员" :bordered="false" :body-style="{ padding: 0 }">
          <template #extra>
            <a>查看更多<RightOutlined /></a>
          </template>
          <a-carousel autoplay>
            <div v-for="(users, index) in groupUserList" :key="index">
              <div v-for="(item, cindex) in users.list" :key="cindex">
                <div class="ud-cell user-list-item">
                  <a-avatar :size="46" :src="item.avatar" />
                  <div class="ud-cell-content">
                    <div class="ud-cell-title">{{ item.name }}</div>
                    <div class="ud-cell-desc">{{ item.desc }}</div>
                  </div>
                  <a-tag :color="['green', 'red'][item.state]">
                    {{ ['在线', '离线'][item.state] }}
                  </a-tag>
                </div>
              </div>
            </div>
          </a-carousel>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  CloudOutlined,
  AppstoreFilled,
  CheckSquareOutlined,
  BellFilled,
  UserOutlined,
  ShoppingCartOutlined,
  FundProjectionScreenOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
  MailOutlined,
  TagsOutlined,
  ControlOutlined,
  MenuOutlined,
  TrophyOutlined,
  RightOutlined
} from '@ant-design/icons-vue'
import draggable from 'vuedraggable'

export default {
  name: 'DashboardWorkplace',
  components: {
    CloudOutlined,
    AppstoreFilled,
    CheckSquareOutlined,
    BellFilled,
    UserOutlined,
    ShoppingCartOutlined,
    FundProjectionScreenOutlined,
    FileSearchOutlined,
    CreditCardOutlined,
    MailOutlined,
    TagsOutlined,
    ControlOutlined,
    MenuOutlined,
    TrophyOutlined,
    draggable,
    RightOutlined
  },
  data() {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total) => `共有 ${total} 条数据`
      },
      loading: true,
      queryParam: {
        page: 1,
        size: 10,
        hosName: '',
        hosCode: '',
        province: '',
        city: ''
      },
      // 最新动态数据
      activities: [
        {
          title: 'SunSmile 解决了bug 登录提示操作失败',
          time: '20:30',
          color: 'gray'
        },
        {
          title: 'Jasmine 解决了bug 按钮颜色与设计不符',
          time: '19:30',
          color: 'gray'
        },
        {
          title: '项目经理 指派了任务 解决项目一的bug',
          time: '18:30'
        },
        {
          title: '项目经理 指派了任务 解决项目二的bug',
          time: '17:30'
        },
        {
          title: '项目经理 指派了任务 解决项目三的bug',
          time: '16:30'
        },
        {
          title: '项目经理 指派了任务 解决项目四的bug',
          time: '15:30',
          color: 'gray'
        },
        {
          title: '项目经理 指派了任务 解决项目五的bug',
          time: '14:30',
          color: 'gray'
        },
        {
          title: '项目经理 指派了任务 解决项目六的bug',
          time: '12:30',
          color: 'gray'
        },
        {
          title: '项目经理 指派了任务 解决项目七的bug',
          time: '11:30'
        },
        {
          title: '项目经理 指派了任务 解决项目八的bug',
          time: '10:30',
          color: 'gray'
        },
        {
          title: '项目经理 指派了任务 解决项目九的bug',
          time: '09:30',
          color: 'green'
        },
        {
          title: '项目经理 指派了任务 解决项目十的bug',
          time: '08:30',
          color: 'red'
        }
      ],
      // 我的任务数据
      taskList: [
        {
          id: 1,
          priority: 1,
          taskName: '解决项目一的bug',
          state: 0
        },
        {
          id: 2,
          priority: 2,
          taskName: '解决项目二的bug',
          state: 0
        },
        {
          id: 3,
          priority: 2,
          taskName: '解决项目三的bug',
          state: 1
        },
        {
          id: 4,
          priority: 3,
          taskName: '解决项目四的bug',
          state: 1
        },
        {
          id: 5,
          priority: 3,
          taskName: '解决项目五的bug',
          state: 2
        },
        {
          id: 6,
          priority: 3,
          taskName: '解决项目六的bug',
          state: 2
        },
        {
          id: 7,
          priority: 7,
          taskName: '解决项目七的bug',
          state: 2
        },
        {
          id: 8,
          priority: 8,
          taskName: '解决项目八的bug',
          state: 1
        }
      ],
      // 项目进度数据
      projectList: [
        {
          id: 1,
          projectName: '项目0000001',
          state: 0,
          startDate: '2020-03-01',
          endDate: '2020-06-01',
          progress: 30
        },
        {
          id: 2,
          projectName: '项目0000002',
          state: 0,
          startDate: '2020-03-01',
          endDate: '2020-08-01',
          progress: 10
        },
        {
          id: 3,
          projectName: '项目0000003',
          state: 1,
          startDate: '2020-01-01',
          endDate: '2020-05-01',
          progress: 60
        },
        {
          id: 4,
          projectName: '项目0000004',
          state: 1,
          startDate: '2020-06-01',
          endDate: '2020-10-01',
          progress: 0
        },
        {
          id: 5,
          projectName: '项目0000005',
          state: 2,
          startDate: '2020-01-01',
          endDate: '2020-03-01',
          progress: 100
        },
        {
          id: 6,
          projectName: '项目0000006',
          state: 3,
          startDate: '2020-01-01',
          endDate: '2020-03-01',
          progress: 100
        },
        {
          id: 7,
          projectName: '项目0000007',
          state: 3,
          startDate: '2020-01-01',
          endDate: '2020-03-01',
          progress: 100
        }
      ],
      // 小组成员数据
      userList: [
        {
          name: 'SunSmile',
          desc: 'UI设计师、交互专家',
          state: 0,
          avatar:
            'https://cdn.eleadmin.com/20200609/c184eef391ae48dba87e3057e70238fb.jpg'
        },
        {
          name: '你的名字很好听',
          desc: '前端工程师',
          state: 0,
          avatar:
            'https://cdn.eleadmin.com/20200609/b6a811873e704db49db994053a5019b2.jpg'
        },
        {
          name: '全村人的希望',
          desc: '前端工程师',
          state: 0,
          avatar:
            'https://cdn.eleadmin.com/20200609/948344a2a77c47a7a7b332fe12ff749a.jpg'
        },
        {
          name: 'Jasmine',
          desc: '产品经理、项目经理',
          state: 1,
          avatar:
            'https://cdn.eleadmin.com/20200609/f6bc05af944a4f738b54128717952107.jpg'
        },
        {
          name: '酷酷的大叔',
          desc: '组长、后端工程师',
          state: 1,
          avatar:
            'https://cdn.eleadmin.com/20200609/2d98970a51b34b6b859339c96b240dcd.jpg'
        }
      ],
      groupUserList: []
    }
  },
  computed: {
    // 当前登录用户信息
    loginUser() {
      return this.$store.state.user.user
    }
  },
  mounted() {
    this.$nextTick(this.getGroupUser())
  },
  methods: {
    getGroupUser() {
      const groupSize = 3
      let groupIndex = -1
      for (let index = 0; index < this.userList.length; index++) {
        if (index % groupSize === 0) {
          groupIndex++
          this.groupUserList.push({ list: [] })
        }
        this.groupUserList[groupIndex].list.push(this.userList[index])
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
/** 用户卡片 */
.workplace-user-card .ud-cell-content {
  overflow: hidden;
}
.workplace-user-card h4 {
  margin-bottom: 6px;
}

.workplace-count-group {
  white-space: nowrap;
  text-align: right;
  flex-shrink: 0;
}

.workplace-count-item {
  display: inline-block;
  margin: 0 4px 0 24px;
}

.workplace-count-name {
  margin-left: 8px;
}

@media screen and (max-width: 992px) {
  .workplace-count-item {
    margin: 0 2px 0 12px;
  }
}

@media screen and (max-width: 768px) {
  .workplace-user-card {
    display: block;
  }

  .workplace-count-group {
    margin-top: 8px;
  }
}

/** 快捷方式 */
.app-link-block {
  padding: 12px;
  text-align: center;
  display: block;
  color: inherit;
}

.app-link-block .app-link-icon {
  color: #69c0ff;
  font-size: 30px;
  margin: 6px 0 10px 0;
}

/** 时间轴 */
.ud-scrollbar-hover
  :deep(.ant-timeline-item-last > .ant-timeline-item-content) {
  min-height: auto;
}

/** 本月目标 */
.workplace-goal-group {
  padding: 48px 0;
  text-align: center;
  position: relative;
}

.workplace-goal-group .workplace-goal-content {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
}

.workplace-goal-group .workplace-goal-num {
  font-size: 40px;
}

/** 小组成员 */
.user-list-item {
  padding: 16px 18px;
}

.user-list-item + .user-list-item {
  border-top: 1px solid hsla(0, 0%, 60%, 0.15);
}
.col-left {
  display: flex;
}
.col-left div {
  margin-right: 15px;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background-color: #777 !important;
}
.ant-carousel :deep(.slick-dots li button) {
  background-color: #ccc !important;
}
/** 表格拖拽 */
.ant-table-tbody tr.sortable-chosen {
  background: hsla(0, 0%, 60%, 0.1) !important;
}

.ant-table-tbody tr.sortable-chosen td {
  background: none !important;
}
</style>
<style lang="less" scoped>
:root {
  --component-background: #000;
}
</style>
